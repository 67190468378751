import { ApisauceInstance } from 'apisauce'
import useClientConfig from 'config/clients/useClientConfig'

/**
 * Expose all endponts for 'account'.
 */
export default (api: ApisauceInstance): ApiObject => ({
  getInfo: () => {
    const {getUseDefaultCriticalMoment} = useClientConfig()
    
    if (getUseDefaultCriticalMoment()) {
      return api.get('account?full=true')
    }

    return api.get('account')
  },
  changePassword: (oldPassword: string, newPassword: string) => api.post('account/change-password', {
    currentPassword: oldPassword,
    newPassword,
  }),
})

