import { isGeoLocationLeaf, renderForCriticalMoment } from '../dashHelper'
import durante from './durante'
import posterior from './posterior'
import posterior2 from './posterior2'
import defaultWidgets from './default'
import laboratorioServiciosSubrogados from './laboratorioServiciosSubrogados'
import serviciosAccesosVasculares from './serviciosAccesosVasculares'
import conveniosSubrogadosHemodialisis from './conveniosSubrogadosHemodialisis'
import laboratoriosMedicosSolicitantes from './laboratoriosMedicosSolicitantes'
import serviciosExternosDiagnostico from './serviciosExternosDiagnostico'
import servicioHemodialisisAmbulatoria from './servicioHemodialisisAmbulatoria'
import defaultLeafWidgets from './defaultLeaf'
import useClientConfig from 'config/clients/useClientConfig'

export default {
    title: 'Demo Dashboard',
    description: 'Dashboard de perfil Demo',
    backgroundClassName: (filters) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI, filtersData: Record<string, any>) => {
        const { getCriticalMomentsRootId } = useClientConfig()
        const cmRootId = getCriticalMomentsRootId()
        return [
            ...defaultWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => renderForCriticalMoment(filters, [cmRootId, 0])
                        && !isGeoLocationLeaf(filters, filtersData),
                })),
            ...defaultLeafWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => renderForCriticalMoment(filters, [cmRootId, 0])
                        && isGeoLocationLeaf(filters, filtersData),
                })),
            ...durante.widgets(filters)
                .map((wdg) => {
                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [9951]),
                    })
                }),
            ...posterior.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [10301]),
                    })
                }),
            ...posterior2.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11301]),
                    })
                }),
            ...laboratorioServiciosSubrogados.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11656]),
                    })
                }),
            ...serviciosAccesosVasculares.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11655]),
                    })
                }),
            ...conveniosSubrogadosHemodialisis.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12052]),
                    })
                }),
            ...laboratoriosMedicosSolicitantes.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11652]),
                    })
                }),
            ...serviciosExternosDiagnostico.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12051]),
                    })
                }),
            ...servicioHemodialisisAmbulatoria.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11653]),
                    })
                }),

        ]
    },
} as DasboardConfig
