
import { DEFAULT_COLORS } from '@components/widgets/apacheEchart/Commons'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const BENAVIDES_CJ_COLORS_CONFIG= [
  {
    //color: '#F42313',
    color: DEFAULT_COLORS[1],
    condition: '<' as const,
    key: 'value',
    value: 6,
  },
  {
    //color: '#FAEF6B',
    color: DEFAULT_COLORS[3],
    condition: '>=' as const,
    key: 'value',
    value: 6,
  },
  {
    //color: '#6DE3B4',
    color: DEFAULT_COLORS[0],
    condition: '>=' as const,
    key: 'value',
    value: 9,
  },
]

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  multipleGaugeByQuestion: {
    ...widgetsLibrary.multipleGaugeByQuestion,
    //@ts-ignore
    config: {
      ...widgetsLibrary.multipleGaugeByQuestion.config,
      colors: BENAVIDES_CJ_COLORS_CONFIG
    }
  },
}

