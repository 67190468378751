import { isArray } from "lodash"

export const filterCriticalMoment = (
  cms: CriticalMoment[],
  selected: number[]
) => {
  const cmsArray: any = cms.map((cm) => {
    return selected.includes(cm.id)
      ? doGetLeaves(cm)
      : filterCriticalMoment(cm.children, selected)
  })
  return [].concat(...cmsArray)
}

export const getLeaves = (cms: CriticalMoment | CriticalMoment[]): CriticalMoment[] => {
  if (isArray(cms)) {
    const arrayLeaves: any = cms.map((child) => doGetLeaves(child))
    return [].concat(...arrayLeaves)
  }

  const leaves: any =
    cms.children === undefined || cms.children.length === 0
      ? [cms.id]
      : cms.children.map((child) => doGetLeaves(child))
  return [].concat(...leaves)
}

export const getChildrens = (cms: CriticalMoment | CriticalMoment[]): CriticalMoment[] => {
  if (cms === undefined || cms === null) {
    return []
  }

  if (isArray(cms)) {
    const arrayChildren: any = cms.map((child) => getChildrens(child))
    return [].concat(...arrayChildren)
  }

  const children: any =
    cms.children === undefined || cms.children.length === 0
      ? [cms]
      : cms.children.map((child) => getChildrens(child))
  return [].concat(...children)
}

const doGetLeaves = (cm: CriticalMoment): CriticalMoment[] => {
  const leaves: any =
    cm.children === undefined || cm.children.length === 0
      ? [cm.id]
      : cm.children.map((child) => doGetLeaves(child))
  return [].concat(...leaves)
}

export const findCriticalMoment = (
  cms: CriticalMoment[],
  selected: number
): CriticalMoment | undefined => {
  if (cms === undefined || cms.length === 0) {
    return undefined
  }
  const criticalMoment: any = cms
    .map((cm) => {
      return selected === cm.id ? cm : findCriticalMoment(cm.children, selected)
    })
    .filter((item) => item?.id !== undefined)[0]

  return criticalMoment
}

export const findCriticalMomentByName = (
  cms: CriticalMoment[],
  name: string
): CriticalMoment | undefined => {
  if (cms === undefined || cms.length === 0) {
    return undefined
  }
  const criticalMoment: any = cms
    .map((cm) => {
      return name === cm.name ? cm : findCriticalMomentByName(cm.children, name)
    })
    .filter((item) => item?.id !== undefined)[0]

  return criticalMoment
}

export const getCriticalMomentsValues = (filtersData: Record<string, any>) => {
  if (
    filtersData.criticalMoments &&
    filtersData.criticalMoments[0] &&
    filtersData.criticalMoments[0].id == 0 &&
    filtersData.criticalMoments[0].children !== undefined &&
    filtersData.criticalMoments[0].children.length === 0
  ) {
    return undefined
  }

  return filtersData.criticalMoments &&
    filtersData.criticalMoments[0] &&
    filtersData.criticalMoments[0].id == 0
    ? filtersData.criticalMoments[0].children
    : filtersData.criticalMoments
}



export const selectedCriticalMoments = (cms: CriticalMoment[], selected: number[]): CriticalMoment[] => {
  const cmsArray: any = cms.map((cm) => {
    return selected.includes(cm.id)
      ? cm
      : selectedCriticalMoments(cm.children, selected)
  })
  return [].concat(...cmsArray)
}
