import { getCmID } from './commonWidgetsJac'
import demoResume from './demoResume'
import demoPosventa from './demoPosventa'
import demoVenta from './demoVenta'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters: any) => {
    /*return filters.criticalMoments.includes(getCmID('root'))
      ? 'default' : 'epsilon-hero-bg'*/
      return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    ...demoResume.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('root')),
    })),
    ...demoVenta.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('venta')),
    })),
    ...demoPosventa.widgets(filters).map((wdg) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(getCmID('servicio')),
    })),
  ],
} as DasboardConfig
