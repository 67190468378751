import React from 'react'
import { HelpDefinition } from "../configs/configCommons"

const alertDetailHelp = <div>
    <b>Comentarios:</b><br />
    ¿Cómo realizar un comentario efectivo?<br />
    <ol type="a">
        <li>Escribe brevemente todos los trabajos realizados con el automóvil del cliente.</li>
        <li>Menciona los documentos que adjuntas.</li>
        <li>Redacta el plan de acción que seguirás para llegar a una resolución satisfactoria
            ante la inconformidad del cliente, así como el tiempo estimado de resolución.</li>
        <li>Por último, agrega en comentarios la postura del cliente ante este
            nuevo plan de acción (Acepto o no acepto).</li>
    </ol>
    <b>Evidencias:</b><br />
    ¿Qué evidencias cargar?<br />
    <p>ODS, diagnóstico, ticket de soporte técnico, ticket de garantías,
        estatus de las piezas requeridas, tiempo de llegada, fotografías, videos o cualquier evidencia necesaria para el caso del cliente.</p>
</div>

const jacHelpDefinition: HelpDefinition = {
    alertDetail: alertDetailHelp
}

export default jacHelpDefinition