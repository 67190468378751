import React, { useState } from 'react'
import { Button, Badge, Drawer, Row, Col, Card, Spin, RadioChangeEvent, Radio } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import useConnection from '@hooks/useConnection'
import { useHistory } from 'react-router-dom'
import useActions from '@hooks/useActions'
import useFilters from '@hooks/useFilters'
import modules from '@modules'
import { MailOutlined } from '@ant-design/icons'

import './NotificationStatus.less'
import { NotificationsCountInfo } from '@modules/AppDataModule'

const NotificationStatus = (): JSX.Element => {
  const [drawerVisible, setDrawerVisible,] = useState<boolean>(false)
  const history = useHistory()
  const dispatchers = useActions(modules.actions.appData, [])
  const { filters, filtersData } = useFilters()
  const {
    loadingNotificationsCount,
    notificationsCount,
    notificationsCountRange,
  } = useConnection<{
    loadingNotificationsCount: boolean
    notificationsCount: NotificationsCountInfo | null
    notificationsCountRange: number
  }>([
    'appData.loadingNotificationsCount',
    'appData.notificationsCount',
    'appData.notificationsCountRange',
  ])

  /*const total = React.useCallback(() => {
    return _.reduce(
      alertsCount,
      (res, counter) => {
        res += counter
        return res
      },
      0
    )
  }, [loadingNotificationsCount, alertsCount,])*/

  //console.log('Rendering notifications status')
  //console.log(notificationsCount)
  //console.log(loadingNotificationsCount)

  const onChangeFilter = React.useCallback((e: RadioChangeEvent) => {
    dispatchers.getCounters({
      filters: {
        ...filters,
        fromDate: dayjs().subtract(e.target.value, 'days').format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD'),
      },
      filtersData: filtersData,
      range: e.target.value,
    })
  }, [])

  return (
    <div className='notifications-notification'>
      <Badge count={0} offset={[2, 5,]} className='notification-count-badge'>
        <Button
          icon={<MailOutlined style={{ fontSize: 20, color: '#BAAEE5' }} />}
          type='link'
          onClick={() => setDrawerVisible(true)}
        />
      </Badge>

      <Drawer
        className='notifications-drawer'
        title=''
        width={500}
        closable
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <Spin spinning={loadingNotificationsCount}>
          <h2>Notificaciones</h2>

          <div className='flex flex-horizontal flex-center'>
            <Radio.Group
              onChange={onChangeFilter}
              value={notificationsCountRange.toString()}
              size={'small'}
            >
              <Radio.Button value='7'>7 días</Radio.Button>
              <Radio.Button value='14'>14 días</Radio.Button>
              <Radio.Button value='30'>30 días</Radio.Button>
              <Radio.Button value='60'>60 días</Radio.Button>
              <Radio.Button value='90'>90 días</Radio.Button>
              <Radio.Button value='180'>6 meses</Radio.Button>
            </Radio.Group>
          </div>

          <Row>
            <Col xs={{ span: 24, }}>
              <Card className='notification-counter notification-counter-answered'>
                <span className='label'>Respondidas: <span className='counter'>{notificationsCount?.answeredCount}</span></span>
              </Card>
            </Col>

            <Col xs={{ span: 24, }}>
              <Card className='notification-counter notification-counter-error'>
                <span className='label'>Con error: <span className='counter'>{notificationsCount?.errorCount}</span></span>
              </Card>
            </Col>

            <Col xs={{ span: 24, }}>
              <Card className='notification-counter notification-counter-processed'>
                <span className='label'>Procesadas: <span className='counter'>{notificationsCount?.processedCount}</span></span>
              </Card>
            </Col>

            <Col xs={{ span: 24, }}>
              <Card className='notification-counter notification-counter-sent'>
                <span className='label'>Enviadas: <span className='counter'>{notificationsCount?.sentCount}</span></span>
              </Card>
            </Col>

            <Col xs={{ span: 24, }}>
              <Card className='notification-counter notification-counter-opened'>
                <span className='label'>Abiertas: <span className='counter'>{notificationsCount?.openedCount}</span></span>
              </Card>
            </Col>

            <Col xs={{ span: 24, }}>
              <Card className='notification-counter notification-counter-clicked'>
                <span className='label'>Cliqueadas: <span className='counter'>{notificationsCount?.clickedCount}</span></span>
              </Card>
            </Col>
          </Row>
        </Spin>
      </Drawer>
    </div>
  )
}

export default NotificationStatus
