import { commonWidgets } from "./commonWidgetsCaffenio";
import { getCommonWidget } from '../dashWidgetHelper'


export default {
  title: 'Lanzamiento Dashboard',
  description: 'Lanzamiento Default',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Calidad',
      type: 'label',
      position: [0, 0],
      size: [4, 1],
      config: { color: '#B7AADC', id: '', type: '' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: '¿Usted es usuario del programa de lealtad MI CAFFENIO?',
      position: [0, 1],
      size: [1, 2],
      indicator: 'experiencia_compra_pregunta_27',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: '¿El sabor del producto era el correcto?',
      helpText: '¿El sabor del producto era el correcto?',
      position: [1, 1],
      size: [1, 2],
      indicator: 'experiencia_compra_pregunta_4',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: '¿Encontraste tu producto?',
      helpText: '¿Encontraste tu producto?',
      position: [2, 1],
      size: [1, 2],
      indicator: 'experiencia_compra_pregunta_5',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: '¿La temperatura era correcta?',
      helpText: '¿La temperatura era correcta?',
      position: [3, 1],
      size: [1, 2],
      indicator: 'experiencia_compra_pregunta_6',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: '¿Te dieron el producto correcto?',
      helpText: '¿Te dieron el producto correcto?',
      position: [0, 3],
      size: [1, 2],
      indicator: 'experiencia_compra_pregunta_7',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'stackedBar',
      title: '¿Cómo fue la presentación del producto?',
      helpText: '¿Cómo fue la presentación del producto?',
      position: [1, 3],
      size: [3, 2],
      indicator: 'experiencia_compra_pregunta_3',
      criticalMomentInclude: [13251],
    }),
    {
      title: 'Limpieza',
      type: 'label',
      position: [0, 5],
      size: [4, 1],
      //config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Los baños estaban limpios?',
      helpText: '¿Los baños estaban limpios?',
      position: [0, 6],
      size: [2, 2],
      indicator: 'experiencia_compra_pregunta_10',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Qué tan limpias estaban las mesas?',
      helpText: '¿Qué tan limpias estaban las mesas?',
      position: [2, 6],
      size: [2, 2],
      indicator: 'experiencia_compra_pregunta_11',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Que tan limpia se ve la pantalla en donde se realizan los pedidos?',
      helpText: '¿Que tan limpia se ve la pantalla en donde se realizan los pedidos?',
      position: [0, 8],
      size: [2, 2],
      indicator: 'experiencia_compra_pregunta_12',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Que tan limpio se ve el punto de venta a simple vista?',
      helpText: '¿Que tan limpio se ve el punto de venta a simple vista?',
      position: [2, 8],
      size: [2, 2],
      indicator: 'experiencia_compra_pregunta_12',
      criticalMomentInclude: [13251],
    }),
    {
      title: 'Servicio',
      type: 'label',
      position: [0, 10],
      size: [4, 1],
      //config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿La persona que te atiende (operador) fue amable y hospitalaria?',
      helpText: '¿La persona que te atiende (operador) fue amable y hospitalaria?',
      position: [0, 11],
      size: [2, 2],
      extraConfigs: {
        showSample: false,
        colors: ['#8CF0EA', '#DB7476']
      },
      indicator: 'experiencia_compra_pregunta_15',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Se pregunto por programa lealtad?',
      helpText: '¿Se pregunto por programa lealtad?',
      position: [2, 11],
      size: [2, 2],
      extraConfigs: {
        showSample: false,
        colors: ['#8CF0EA', '#DB7476']
      },
      indicator: 'experiencia_compra_pregunta_16',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Al momento de la despedida: el operador fue amable y/o se despidió?',
      helpText: '¿Al momento de la despedida: el operador fue amable y/o se despidió?',
      position: [0, 13],
      size: [2, 2],
      extraConfigs: {
        showSample: false,
        colors: ['#8CF0EA', '#DB7476']
      },
      indicator: 'experiencia_compra_pregunta_17',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Al momento de la despedida: el operador te entregó el ticket?',
      helpText: '¿Al momento de la despedida: el operador te entregó el ticket?',
      position: [2, 13],
      size: [2, 2],
      extraConfigs: {
        showSample: false,
        colors: ['#8CF0EA', '#DB7476']
      },
      indicator: 'experiencia_compra_pregunta_18',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿El operador respeto la forma de pago y dio el cambio correcto?',
      helpText: '¿El operador respeto la forma de pago y dio el cambio correcto?',
      position: [0, 15],
      size: [2, 2],
      extraConfigs: {
        showSample: false,
        colors: ['#8CF0EA', '#DB7476']
      },
      indicator: 'experiencia_compra_pregunta_19',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Se observa orden dentro del punto de venta?',
      helpText: '¿Se observa orden dentro del punto de venta?',
      position: [2, 15],
      size: [2, 2],
      extraConfigs: {
        showSample: false,
        colors: ['#8CF0EA', '#DB7476']
      },
      indicator: 'experiencia_compra_pregunta_24',
      criticalMomentInclude: [13251],
    }),
    {
      title: 'Análisis de sentimientos',
      type: 'label',
      position: [0, 17],
      size: [4, 1],
      //config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 18],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [13251],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 22],
      size: [2, 3],
      criticalMomentInclude: [13251],
    }),


  ]
}
