import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'
import { commonWidgets } from './commonWidgetsOxxo'

export default {
  title: 'Gasolina Dashboard',
  description: 'Dashboard de perfil Gasolina',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getLineDetailedRadar({
      mainIndicator: 'satisfaction',
      radialIndicators: [
        'satisfaction',
        'service-speed',
        'service',
        'willingness-to-recommend',
      ],
      radialType: 'byGeo',
      position: [0, 0],
      size: [4, 3],
      title: 'Satisfacción General',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
      },
      criticalMomentInclude: [1],
      indicatorsNames: {
        satisfaction: 'Satisfacción',
        service: 'Servicio',
        'service-speed': 'Velocidad',
        'willingness-to-recommend': 'NPS',
      },
    }),
    /*getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'oxxoLineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'satisfaction',
      criticalMomentInclude: [11702],
    }),*/
    //1
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-count',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'Respuesta alertas mensual',
      position: [2, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'Respuesta alertas semanal',
      position: [3, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentInclude: [11702],
    }),
    //2
    {
      title: 'Tendencias',
      type: 'label',
      position: [0, 6],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Satisfacción general',
      position: [0, 7],
      size: [1, 2],
      indicator: 'satisfaction',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Indicador de Rapidez',
      position: [1, 7],
      size: [1, 2],
      indicator: 'service-speed',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Indicador de Atención',
      position: [2, 7],
      size: [1, 2],
      indicator: 'service',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'NPS',
      position: [3, 7],
      size: [1, 2],
      indicator: 'willingness-to-recommend',
      mapper: (data, extra) => {
        return data.map((item: any, idx: any) => ({
          ...item,
          groupName: item.groupName + ' (' + item.value + ')',
        }))
      },
      criticalMomentInclude: [11702],
    }),
    //3
    {
      title: 'Estaciones',
      type: 'label',
      position: [0, 9],
      size: [4, 0.5],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de estaciones',
      position: [0, 10],
      size: [2, 2],
      indicator: 'willingness-to-recommend',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'Top 5 Mejores estaciones',
      position: [2, 10],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      },
      indicator: 'satisfaction',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'Top 5 Estaciones a mejorar',
      position: [3, 10],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      },
      indicator: 'satisfaction',
      criticalMomentInclude: [11702],
    }),
    //4
    {
      title: 'Sentimientos',
      type: 'label',
      position: [0, 12],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Cuál (es) de estos servicios le ofrecieron?',
      position: [0, 13],
      size: [2, 2],
      indicator: 'ADDICIONAL_SERVICE_OPTIONS',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Sentimiento positivo con más frecuencia',
      position: [2, 13],
      size: [2, 1],
      indicator: 'sentiment',
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Sentimiento negativo con más frecuencia',
      position: [2, 14],
      size: [2, 1],
      indicator: 'sentiment',
      criticalMomentInclude: [11702],
    }),
    {
      title: 'Diagrama de Ishikawa',
      type: 'label',
      position: [0, 15],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 16],
      size: [4, 2],
      indicator: 'sentiment',
      criticalMomentInclude: [11702],
    }),
    {
      title: 'Análisis',
      type: 'label',
      position: [0, 18],
      size: [4, 1],
    },

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 19],
      size: [4, 4],
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lastComments',
      position: [0, 23],
      size: [2, 3],
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 23],
      size: [2, 3],
      criticalMomentInclude: [11702],
    }),
    {
      title: 'Tiempos de respuesta',
      type: 'label',
      position: [0, 26],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertFirstResponseBarByWeek',
      indicator: 'alert-first-response-time',
      title: 'Tiempo de respuesta alertas semanal. Base 36 hs.',
      position: [0, 27],
      size: [4, 2],
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertFirstResponseBarByMonth',
      indicator: 'alert-first-response-time',
      title: 'Tiempo de respuesta alertas mensual. Base 36 hs.',
      position: [0, 29],
      size: [4, 2],
      criticalMomentInclude: [11702],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertFirstResponseBarByGeo',
      title: 'Tiempo de respuesta alertas por geo. Base 36 hs.',
      position: [0, 31],
      size: [4, 2],
      criticalMomentInclude: [11702],
    }),
  ],
} as DasboardConfig
