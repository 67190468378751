import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'


export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const CJ_IAMSA_ORDER = {
  'Compra': 1,
  'Abordaje': 2,
  'Autobús-Comodidad': 3,
  'Autobús-Funcionalidad': 4,
  'Autobús-Limpieza': 5,
  'Conductor': 6,
  'Arribo': 7
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  xmGauge: {
    title: 'Experiencia de marca',
    // [Column, Row]
    position: [0, 0],
    size: [1, 2],
    config: {
      id: 'xm-gauge',
      type: 'gauge',
      showHistoric: false,
      label: 'Experiencia de marca',
      maxValue: 5,
      decimals: 2,
      indicators: [
        {
          indicator: 'EXPERIENCIA_MARCA',
          keyExtract: ['value'],
          value: 'value',
        },
      ],
    },
  },
  xmcGauge: {
    title: 'Experiencia de Momentos Críticos',
    // [Column, Row]
    position: [1, 0],
    size: [1, 2],
    config: {
      id: 'xmc-gauge',
      type: 'gauge',
      showHistoric: false,
      label: 'Experiencia de Momentos Críticos',
      maxValue: 5,
      decimals: 2,
      indicators: [
        {
          indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
          keyExtract: ['value'],
          value: 'value',
        },
      ],
    },
  },
  xgGauge: {
    title: 'Satisfacción general',
    // [Column, Row]
    position: [2, 0],
    size: [1, 2],
    config: {
      id: 'xg-gauge',
      type: 'gauge',
      showHistoric: false,
      maxValue: 5,
      decimals: 2,
      indicators: [
        {
          indicator: 'SATISFACCION_GENERAL',
          keyExtract: ['value'],
          value: 'value',
        },
      ],
    },
  },
  npsGauge: {
    title: 'NPS General',
    // [Column, Row]
    position: [2, 0],
    size: [1, 2],
    config: {
      id: 'nps-gauge',
      type: 'gauge',
      showHistoric: false,
      maxValue: 100,
      decimals: 2,
      labelFontSize: 50,
      indicators: [
        {
          indicator: 'NPS',
          keyExtract: ['value'],
          value: 'value',
        },
      ],
    },
  },
  nps: {
    title: 'NPS',
    // [Column, Row]
    position: [3, 0],
    size: [1, 2],
    config: {
      id: 'nps',
      type: 'nps',
      color: '#CCC',
      showHistoric: false,
      indicators: [
        {
          indicator: 'nps',
          keyExtract: ['passives', 'detractors', 'promoters', 'value'],
          value: 'value',
        },
      ],
    },
  },
  xgmcExperience: {
    title: 'Experiencia por cada momento crítico',
    // [Column, Row]
    position: [0, 2],
    size: [4, 2],
    config: {
      id: 'mcg-experience',
      type: 'bar',
      //fillMode: 'gradient',
      fillMode: '',
      colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354'],
      showValues: true,
      percentageScale: false,
      maxValue: 5,
      wrapTexts: true,
      mapper: (data) => {
        //orden solicitado por el cliente
        const order = {
          'Compra': 0,
          'Abordaje': 1,
          'Conductor': 2,
          'Autobús-Comodidad': 3,
          'Autobús-Limpieza': 4,
          'Autobús-Funcionalidad': 5,
          'Arribo': 6,
        }

        const dataCopy = [...data]
        dataCopy.sort((a: any, b: any) => b.value - a.value)

        const result: any[] = []

        dataCopy.forEach((item) => {
          result.push(item)
        })

        return result

      },
      indicators: [
        {
          indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
          grouped: 'question',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { value: 'desc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
            groupNameFormat: 'description',
            //featureInclude: ['zoomOut'],
            //groupByLevel: 2,
          },
        },
      ],
    },
  },
  stmcExperience: {
    title: 'Tipo de servicio por momento crítico',
    // [Column, Row]
    position: [0, 15],
    size: [4, 2],
    config: {
      id: 'stmc-experience',
      type: 'bar',
      //fillMode: 'gradient',
      fillMode: '',
      colors: ['#ccc9c8', '#b2afae', '#868484', '#5d5c5c', '#0a0a0a'],
      showValues: true,
      percentageScale: false,
      maxValue: 5,
      indicators: [
        {
          indicator: 'EXPERIENCIA_MARCA',
          grouped: 'logicalLocation',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          sort: { value: 'desc' },
          singleton: true,
          extras: {
            featureExclude: ['groupByTopLocation'],
          },
        },
      ],
    },
  },
  xgmExperience: {
    title: 'Experiencia General por Marca',
    // [Column, Row]
    position: [0, 17],
    size: [4, 2],
    config: {
      id: 'xgm-experience',
      type: 'bar',
      //fillMode: 'gradient',
      fillMode: '',
      colors: '#7693a1',
      showValues: true,
      percentageScale: false,
      maxValue: 5,
      indicators: [
        {
          indicator: 'EXPERIENCIA_MARCA',
          grouped: 'logicalLocation',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { value: 'desc' },
          extras: {
            groupByLevel: 2,
          },
        },
      ],
    },
  },
  xgbExperience: {
    title: 'Experiencia de Marca',
    // [Column, Row]
    position: [0, 19],
    size: [4, 2],
    config: {
      id: 'xgb-experience',
      type: 'bar',
      //fillMode: 'gradient',
      fillMode: '',
      colors: ['#9b633f', '#342115', '#c86120', '#a75019'],
      showValues: true,
      percentageScale: false,
      maxValue: 5,
      indicators: [
        {
          indicator: 'EXPERIENCIA_MARCA',
          grouped: 'question',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { value: 'desc' },
          extras: {
            //featureExclude: ['groupByTopLocation'],
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
  xgmccExperience: {
    title: 'Experiencia de momentos críticos por canal',
    // [Column, Row]
    position: [0, 23],
    size: [4, 2],
    config: {
      id: 'xgmcc-experience',
      type: 'bar',
      //fillMode: 'gradient',
      fillMode: '',
      colors: ['#c7f3b0', '#b8dba5', '#96bd81', '#6e8e5d'],
      showValues: true,
      percentageScale: false,
      maxValue: 5,
      indicators: [
        {
          indicator: 'SATISFACCION_GENERAL',
          grouped: 'channel',
          keyExtract: ['group', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          sort: { value: 'desc' },
        },
      ],
    },
  },
}
