import { answeredMapper, calidadDatosMapper, invalidMapper, porcRespuestasMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar, radialMapperByIndicator } from "../widgetsLibrary/widgetsLine"
import { commonWidgets, getCmID, jacCustomerJourneyMapper } from "./commonWidgetsJac"

export default {
  title: "Demo Post postventa Dashboard",
  description: "Demo Post postventa Dashboard",
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getLineDetailedRadar({
      mainIndicator: 'Experiencia_CSI',
      radialIndicators: [
        'Experiencia_CSI',
        'Competencia_AS',
        'recepcion_CSI',
        'Avance_CSI',
        'entregapv',
        'Reparacion_CSI'
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 3],
      title: 'widgets.snPostventa',
      criticalMomentInclude: [getCmID('servicio')],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        groups: ['JAC', 'Zona 1 P', 'Zona 2 P', 'Zona 3 P', 'Zona 4 P', 'Zona 5 P'],
        maxValue: 100
      },
      groupByLevel: 1,
      useNormalizedRadial: true,
      indicatorsNames: {
        'Experiencia_CSI': 'Mantenimiento',
        'Competencia_AS': 'Atención y amabilidad',
        'recepcion_CSI': 'Recepción',
        'Avance_CSI': 'Seguimiento',
        'entregapv': 'Entrega',
        'Reparacion_CSI': 'Retorno'
      },
      mainSort: { sortBy: 'group_1', value: 'asc' }
    }),
    //1
    {
      title: 'labels.surveys',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'labels.alerts',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      subTitle: 'widgets.surveyCountMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      subTitle: 'widgets.surveyCountWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentInclude: [getCmID('servicio')],
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentInclude: [getCmID('servicio')],
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [0, 6],
      size: [2, 2],
      indicator: 'recomendacionpv',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 6],
      size: [1, 2],
      indicator: 'calidad-gauge',
      mapper: calidadDatosMapper,
      url: 'dashboard/status',
      extraIndicatorConfigs: {
        keyExtract: ['resume'],
        singleton: true
      },
      extras: {
        useLcmApi: true,
        workflowName: 'postventa'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'postventa-respuestas-gauge',
      mapper: porcRespuestasMapper,
      position: [3, 6],
      size: [1, 2],
      url: 'dashboard/status',
      extraIndicatorConfigs: {
        keyExtract: ['resume'],
        singleton: true
      },
      extras: {
        useLcmApi: true,
        workflowName: 'postventa'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      mapper: jacCustomerJourneyMapper,
      indicator: 'MERGE',
      position: [0, 8],
      size: [4, 2],
      extraConfigs: {
        chartRadius: '80%',
        chartCenter: ['50%', '45%'],
        labelFontSize: 40,
        groupNameFormat: 'description',
        maxValue: 100,
      },
      extras: {
        indicators: 'POSTVENTA_CUSTOMER_JOURNEY,Avance_CSI,Reparacion_CSI',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 10],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 10],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de estaciones',
      position: [0, 11],
      size: [2, 2],
      indicator: 'recomendacionpv',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 11],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'recomendacionpv',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 11],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'recomendacionpv',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'widgets.wordCount',
      position: [0, 13],
      size: [4, 2],
      criticalMomentInclude: [getCmID('servicio')],
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: "widgets.sentimentAnalysis",
      position: [0, 15],
      size: [4, 3],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'widgets.ishikawa',
      position: [0, 18],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
    })
  ],
} as DasboardConfig
