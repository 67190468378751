import { COLORS_CONFIG_BAR } from "@components/widgets/apacheEchart/Commons"
import _ from "lodash"


export const widgetsMap: CommonWidget = {



  /**************************************************************************
   *                                MAP
  ***************************************************************************/

  mapByGeos: {
    title: 'Mapa de estaciones',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'map-by-geo-locations',
      type: 'map',
      maxItemSize: 70,
      minItemSize: 1,
      itemLabel: '',
      itemLabelFontSize: 15,
      removePadding: true,
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => ({
          group: item.group ?? item['group_0'],
          value: item?.value && _.isNumber(item.value) ? item?.value : item?.value?.count,
          geoCoordinate_x: item.geoCoordinates?.x,
          geoCoordinate_y: item.geoCoordinates?.y,
        }))
      },
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['value', 'group', 'count', 'geoCoordinates', 'group_0'],
          value: 'value',
          singleton: true,
          extras: {
            featureExclude: ['groupByTopLocation']
          },
        },
      ],
    },
  },

}
