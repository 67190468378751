import {
  ActionsDispatcher,
  createModule,
  EffectParam,
  STATUS,
} from "redux-acron"
import { ActionCreatorsMapObject, AnyAction } from "redux"
import useActions from "@hooks/useActions"
import sagasRunner from "@utils/SagasRunner"
import api from "@services/api"
import { call, put, SagaReturnType, all } from "redux-saga/effects"

export type AccountMessage = {
  id: number
  originId: number
  createdAt: string
  workflowName: string
  entityType: string
  entityId: string
  discriminator: string
  accountId: string
  customerServiceTime: string
  fields: any
}

export type AccountMessagesState = {
  list: object
  loadingList: boolean

  total: number
  page: number
  size: number

  filters: object
} // & BASE_INITIAL_STATE<SurveyResponse>

export const ACCOUNT_MESSAGE_MODULE_NAME = "account-messages"

const AccountMessagesModule = createModule<AccountMessagesState>({
  name: ACCOUNT_MESSAGE_MODULE_NAME,
  state: {
    list: [],
    loadingList: false,

    total: 0,
    page: 0,
    size: 15,

    filters: {}
  },
  handlers: {
    //...baseHandlers<SurveyResponse>(),

    list: (state, { payload, status }) => {
      if (status === STATUS.PENDING_STATUS || !status) {
        return state.merge({
          loadingList: true,
          list: [],
          total: 0,
          page: payload.page,
          size: payload.size,
        })
      }

      if (status === STATUS.SUCCESS_STATUS) {
        return state.merge({
          loadingList: false,
          list: payload.data,
          total: payload.total,
          page: payload.page,
          size: payload.size,
        })
      }

      if (status === STATUS.FAILURE_STATUS) {
        return state.merge({
          loadingList: false,
        })
      }

      return state
    }
  },

  effects: {
    list: {
      type: "all",
      *callback(
        actions: ActionCreatorsMapObject,
        {
          payload,
        }: EffectParam<{
          accountId: number
          page: number
          size: number
          filters: object
          query: object
          sort: string
        }>
      ) {
        const response: SagaReturnType<typeof api.clientAccounts.accountMessages> =
          yield call(
            api.clientAccounts.accountMessages,
            payload
            /*payload.accountId,
            payload.page,
            payload.size,
            payload.filters,
            payload.query,
            payload.sort*/
          )

        if (response.ok) {
          yield put(
            actions.list(
              {
                data: response.data,
                total: Number(response.headers?.["x-total-count"]),
                page: payload.page,
                size: payload.size,
              },
              STATUS.SUCCESS_STATUS
            )
          )
        } else {
          yield put(actions.list({}, STATUS.FAILURE_STATUS))
        }
      },
    },
  },
})

export default AccountMessagesModule

// Creates a reducer with the initialState and the action handlers
export const reducer = (
  state: any = AccountMessagesModule.initialState,
  action: AnyAction
): AccountMessagesState => {
  const handler = AccountMessagesModule.actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export const rootSagas = sagasRunner(AccountMessagesModule.sagas, ACCOUNT_MESSAGE_MODULE_NAME)

export const actionDispacher = (): ActionsDispatcher =>
  useActions(AccountMessagesModule.actionCreators, [])
