
export const canUpdateAlert = (userProfiles: UserProfile[]) => {
    return (
        userProfiles &&
        userProfiles[0] &&
        userProfiles[0].config &&
        userProfiles[0].config.access &&
        userProfiles[0].config.access.alerts &&
        userProfiles[0].config.access.alerts.length > 0 &&
        (userProfiles[0].config.access.alerts.includes('close') ||
            userProfiles[0].config.access.alerts.includes('update'))
    )
}

/**
 * Action Plans
 */

const actionPlanAccessIncludes = (userProfiles: UserProfile[], perm: string) => {
    return (
        userProfiles &&
        userProfiles[0] &&
        userProfiles[0].config &&
        userProfiles[0].config.access &&
        userProfiles[0].config.access['action-plans'] &&
        userProfiles[0].config.access['action-plans'].length > 0 &&
        userProfiles[0].config.access['action-plans'].includes(perm)
    )
}

/**
* Alerts
*/
const alertAccessIncludes = (userProfiles: UserProfile[], perm: string) => {
    return (
        userProfiles &&
        userProfiles[0] &&
        userProfiles[0].config &&
        userProfiles[0].config.access &&
        userProfiles[0].config.access['alerts'] &&
        userProfiles[0].config.access['alerts'].length > 0 &&
        userProfiles[0].config.access['alerts'].includes(perm)
    )
}

export const canCreateActionPlan = (userProfiles: UserProfile[]) => {
    return actionPlanAccessIncludes(userProfiles, 'create')
}

export const canAddActivityActionPlan = (userProfiles: UserProfile[]) => {
    return actionPlanAccessIncludes(userProfiles, 'add-activity')
}

export const canCloseActionPlan = (userProfiles: UserProfile[]) => {
    return actionPlanAccessIncludes(userProfiles, 'close')
}

export const canDeleteActionPlan = (userProfiles: UserProfile[]) => {
    return actionPlanAccessIncludes(userProfiles, 'delete')
}

export const canCreateActionPlanCausaRaiz = (userProfiles: UserProfile[]) => {
    return actionPlanAccessIncludes(userProfiles, 'root-cause')
}

export const canCloseAlert = (userProfiles: UserProfile[], authorities: Array<Authority>) => {
    return alertAccessIncludes(userProfiles, 'update')
        || authorities.includes('ROLE_ALERT_ADMIN')
}

export const canAddCommentAlert = (userProfiles: UserProfile[], authorities: Array<Authority>) => {
    return true //alertAccessIncludes(userProfiles, 'addComment')
}

export const canAddEvidenceAlert = (userProfiles: UserProfile[], authorities: Array<Authority>) => {
    return true //alertAccessIncludes(userProfiles, 'addEvidence')
}

export const canAccessAlertsStatus = (userProfiles: UserProfile[]) => {
    return alertAccessIncludes(userProfiles, 'main')
}

