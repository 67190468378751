import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, haWordCountWithLinksMapper  } from './commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_10',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
     }
  }),
  
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haGeoLocationPie',
    title: 'Hospital de procedencia.',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: true,
      valueSymbol:''
    },
    indicator: 'laboratorio_servicios_subrogados_pregunta_99',
    criticalMomentInclude: [11656]
  }),
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 3],
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general con el servicio recibido.',
    position: [0, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_3',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Tiempo de entrega de los resultados.',
    position: [1, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_4',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Calidad de la información del reporte de resultados.',
    position: [2, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_5',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Asistencia técnica y solución de necesidades.',
    position: [3, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_6',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 6],
    size: [4, 1],
   },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención del área de referencia.',
    position: [0, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_7',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención y servicio del personal del área de referencia',
    position: [1, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'laboratorio_servicios_subrogados_pregunta_8',
    criticalMomentInclude: [11656],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'textAnalysis',
     position:[0,9], 
     size: [2, 3],
     indicator: 'SENTIMENT',
     criticalMomentInclude: [11656],
     extraConfigs:{
        hideBars: true
     }
   }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'wordCountWithLinks',
     title: 'Nube de Palabras / Frecuencia en comentarios',
     position: [2, 9],
     size: [2, 3],
     criticalMomentInclude: [11656],
     mapper: haWordCountWithLinksMapper
   }),
   
]

export default {
  title: 'Hospital Angeles Laboratorio (Servicios Subrogados)',
  description: 'Hospital Angeles Laboratorio (Servicios Subrogados)',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig
