import { selectedGeos } from '@utils/GeoUtils'
import { renderForGeoLocation } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from "./commonWidgetsIamsa"
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'

const getIamsaRankingNormalWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  return [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      key: 'best-offices',
      title: 'Mejores oficinas',
      position: [0, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      key: 'worst-offices',
      title: 'Oficinas a mejorar',
      position: [1, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBestAndTopLocation',
      key: 'best-zones',
      title: 'Mejores divisiones',
      position: [2, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorstAndTopLocation',
      key: 'worst-zones',
      title: 'Divisiones a mejorar',
      position: [3, 9],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76',
      },
      indicator: 'SATISFACCION_GENERAL',
    }),
  ]
}

const getIamsaRankingLeafWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  return [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingPositionHighestScope',
      key: 'ranking-position-highest-scope',
      title: 'Posición entre oficinas de la misma división',
      position: [0, 9],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingPositionLowestScope',
      key: 'ranking-position-lowest-scope',
      title: 'Posición entre todas las oficinas',
      position: [2, 9],
      size: [2, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SATISFACCION_GENERAL',
    })
  ]
}

const getRankingIamsaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => {
  const geos = selectedGeos(filtersData.geographicDistributions, filters.geographicDistributions)

  if (geos !== undefined
    && geos.length === 1
    && geos[0] !== undefined
    && geos[0].children !== undefined
    && geos[0]?.children?.length === 0) {
    return getIamsaRankingLeafWidgets(filters, filtersData)
  } else {
    return getIamsaRankingNormalWidgets(filters, filtersData)
  }
}

const getIamsaWidgets = (filters: FiltersUI, filtersData: Record<string, any>) => [
  getLineDetailedRadar({
    mainIndicator: 'NPS',
    radialIndicators: [
      'NPS',
      'SATISFACCION_GENERAL',
      'AUTOBUS_LIMPIEZA',
      'VIAJE', // Viaje
      'AUTOBUS_COMODIDAD'
    ],
    position: [0, 0],
    size: [4, 4],
    title: 'Satisfacción General',
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
      showRadialChart: true,
      decimals: 2
    },
    radialType: 'byIndicator',
    groupByLevel: 2,
    radialGrouped: 'logicalLocation',
    indicatorsNames: {
      'NPS': 'NPS',
      'SATISFACCION_GENERAL': 'Satisfacción',
      'AUTOBUS_LIMPIEZA': 'Limpieza',
      'VIAJE': 'Conductor', // Viaje
      'AUTOBUS_COMODIDAD': 'Comodidad'
    },
    extras: {
      periods: 0
    }
  }),
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'lineDetailedByMonthAndGeo',
    title: 'NPS',
    position: [0, 0],
    size: [4, 3],
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
      decimals: 2
    },
    extras: {
      periods: 0
    },
    indicator: 'NPS',
  }),*/
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgGauge',
    position: [0, 4],
    size: [1, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'npsGauge',
    position: [1, 4],
    size: [1, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xmGauge',
    position: [2, 4],
    size: [1, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xmcGauge',
    position: [3, 4],
    size: [1, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgmcExperience',
    position: [0, 6],
    size: [4, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'rankingByQuestionBest',
    title: 'Felicidades! Lo que mejor hacemos',
    indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
    position: [0, 8],
    size: [2, 1],
    extraConfigs: {
      limit: 1,
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'rankingByQuestionWorst',
    title: 'Nuestro Reto. Lo que vamos a mejorar',
    indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
    position: [2, 8],
    size: [2, 1],
    extraConfigs: {
      limit: 1,
    }
  }),
  ...getRankingIamsaWidgets(filters, filtersData),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: [0, 11],
    size: [4, 4],
    indicator: 'SENTIMENT',
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawaByLogical',
    title: 'Puntos de Dolor',
    position: [0, 15],
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: [0, 18],
    size: [2, 2],
    mapper: (data: any[], extra: any) => {
      console.log('nube de palabras mapper')
      console.log(data)
      return data
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'mapByGeos',
    title: 'Mapa puntos de ventas',
    position: [2, 18],
    size: [2, 2],
    indicator: 'NPS',
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'lastComments',
    indicator: 'COMMENTS',
    position: [0, 20],
    size: [4, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'stmcExperience',
    position: [0, 22],
    size: [4, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgmExperience',
    position: [0, 24],
    size: [4, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgbExperience',
    position: [0, 26],
    size: [4, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    key: 'xgmcd-experience',
    title: 'Experiencia de momentos críticos por división',
    indicator: 'SATISFACCION_GENERAL',
    name: 'barByGeo',
    position: [0, 28],
    size: [4, 2],
    extraConfigs: {
      colors: '#c0b7f4',
      rotateXAxisLegend: true,
      wrapTexts: true,
      labelFontSize: 10
    },
    extraIndicatorConfigs: {
      sort: { value: 'desc' },
    },
    extras: {
      groupByLevel: 2,
    }
  }),
  /*getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgmcdExperience',
    position: [0, 27],
    size: [4, 2],
  }),*/
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'xgmccExperience',
    position: [0, 30],
    size: [4, 2],
  }),
]

export default {
  title: 'Marketing Dashboard',
  description: 'Dashboard de Director de Marketing',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI, filtersData: Record<string, any>) => getIamsaWidgets(filters, filtersData),
} as DasboardConfig
