import { ApisauceInstance } from 'apisauce'

const parseFilters = (filters: any) => {
  //console.log('parsing filters!!!!')
  //console.log(filters)
  const { filters: customFilters, ...rest } = filters

  return {
    ...customFilters,
    ...rest,
    //accountId: filters.filters['accountId'],
    dashboardUser: false
  }
}

/**
 * Expose all endponts for 'account'.
 */
export default (vocApi: ApisauceInstance, lcmApi: ApisauceInstance): ApiObject => ({
  accounts: (filters: any) => {
    return lcmApi.get('accounts', parseFilters(filters))
  },
  accountMessages: (filters: any) => {
    return lcmApi.get('messages', parseFilters(filters))
  },
  messageNotifications: (filters: any) => {
    return lcmApi.get('notifications', parseFilters(filters))
  },
  notificationSurveys: (filters: any) => {
    return vocApi.get('survey-responses', parseFilters(filters))
  },
})

