import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper  } from './commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Ingreso',
    type: 'label',
    position: [1, 0],
    size: [3, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_16',
    criticalMomentInclude: [11301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿Qué lo motivó a elegir nuestro Hospital para su atención médica?',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: false
    },
    indicator: 'posterior_dos_pregunta_19',
    criticalMomentInclude: [11301]
  }),
  {
    title: 'Ingreso',
    type: 'label',
    position: [0, 3],
    size: [2, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Instalaciones',
    type: 'label',
    position: [2, 3],
    size: [2, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Amabilidad y calidez del personal de Admisión',
    position: [0, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_6',
    criticalMomentInclude: [11301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Facilidad en el proceso de Admisión',
    position: [1, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_5',
    criticalMomentInclude: [11301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Funcionalidad de las instalaciones',
    position: [2, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_7',
    criticalMomentInclude: [11301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Limpieza y estado general de las instalaciones',
    position: [3, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_8',
    criticalMomentInclude: [11301]
  }),
  {
    title: 'Servicios Alimenticios',
    type: 'label',
    position: [0, 6],
    size: [1, 1],
   },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Agrado de los alimentos',
    position: [0, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_9',
    criticalMomentInclude: [11301]
  }),
  {
    title: 'Atención personal de enfermería',
    type: 'label',
    position: [1, 6],
    size: [1, 1],
  
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción del trato recibido del personal de enfermería',
    position: [1, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_10',
    criticalMomentInclude: [11301]
  }),
  {
    title: 'Dolor',
    type: 'label',
    position: [2, 6],
    size: [1, 1],
  
  },
  {
    title: 'Atención personal médico',
    type: 'label',
    position: [3, 6],
    size: [1, 1],
    },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Control de dolor y seguimiento',
    position: [2, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_11',
    criticalMomentInclude: [11301]
  }),
  
 getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: '¿El personal médico utilizó un lenguaje fácil de entender?',
    position: [3, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_12',
    criticalMomentInclude: [11301]
  }),
  {
    title: 'Alta',
    type: 'label',
    position: [0, 9],
    size: [1, 1],
  },
  {
    title: 'Satisfacción',
    type: 'label',
    position: [1, 9],
    size: [1, 1],
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Proceso de alta en general',
    position: [0, 10],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_13',
    criticalMomentInclude: [11301]
  }),
 
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general durante su estancia',
    position: [1, 10],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_dos_pregunta_14',
    criticalMomentInclude: [11301]
  }),
]

export default {
  title: 'Posterior II',
  description: 'Dashboard de perfil Posterior II',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig
