import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'
import { MESSAGE_NOTIFICATION_MODULE_NAME } from './MessageNotificationsModule'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/message-notifications',
  layout: true,
  getComponent: async () => {
    const MessageNotificationsLayout = await import(
      /* webpackChunkName: "surveys-modules" */ './components/MessageNotificationsLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "surveys-modules" */ './MessageNotificationsModule'
    )

    injectReducer(store, { key: MESSAGE_NOTIFICATION_MODULE_NAME, reducer, })
    injectSagas(store, rootSagas)

    return {
      children: MessageNotificationsLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/:id/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const MessageNotificationsTableView = await import('../MessageNotifications/components/MessageNotificationsTableView')
        return { children: MessageNotificationsTableView.default }
      },
    },
    {
      path: '/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const MessageNotificationsTableView = await import('../MessageNotifications/components/MessageNotificationsTableView')
        return { children: MessageNotificationsTableView.default }
      },
    },
  ],
})

