import parseFilters from '@utils/parseFilters'
import parseFiltersIndicators from '@utils/parseFiltersIndicators'
import parseUrlFilteres from '@utils/parseUrlFilteres'
import RestApiBuilder from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'
import useClientConfig from 'config/clients/useClientConfig'
import _ from 'lodash'

/**
 * Expose all endponts for 'reports'.
 */
export default <T>(vocApi: ApisauceInstance, lcmApi: ApisauceInstance): ApiObject => {
  const { getSurveyResponsesUrl, getSurveyResponsesType } = useClientConfig()

  return {
    ...RestApiBuilder<T>(getSurveyResponsesUrl(), vocApi),
    getResponses: (
      page: number,
      size: number,
      filters?: FiltersUI,
      query?: object,
      sort?: string
    ) => {

      if (getSurveyResponsesType() === 'post'
        || getSurveyResponsesType() === 'post-context') {
          
        console.log('get survey responses')
        console.log(filters)

        const urlFilters = {
          page,
          ...(size && { size, }),
          //...(query && !_.isEmpty(query) && { filter: parseQuery(query), }),
          ...(sort && { sort, })
        }

        const resultType = getSurveyResponsesType() === 'post-context'
          ? 'context,questionResponses'
          : undefined

        return vocApi.post('/survey-responses/filter?' + parseUrlFilteres(urlFilters), {
          //page,
          //...(size && { size, }),
          //...(query && !_.isEmpty(query) && { filter: parseQuery(query), }),
          //...(sort && { sort, }),
          ...(filters && parseFiltersIndicators(filters)),
          filter: undefined,
          resultType: resultType
        })
      } else {
        return vocApi.get('/survey-responses', {
          page,
          ...(size && { size, }),
          ...(sort && { sort, }),
          ...(filters && parseFilters(filters)),
        })
      }
    },
    vocCounters: (filters: FiltersUI) => vocApi.post('survey-responses/counters', {
      ...(filters && parseFiltersIndicators(filters)),
    }),
    lcmCounters: (filters: FiltersUI) => {
      const lcmCountersMock = {
        data: {
          resume: {
            answered: 10,
            clicked: 12,
            notifications: 20,
            opened: 30,
            processed: 32,
            queued: 15,
            sent: 12,
            error: 25,
          }
        },
        ok: true,
      }

      /*return new Promise<any>((resolve, reject) => {
        setTimeout(() => resolve(lcmCountersMock), 500)
      })*/

      return lcmApi.get('dashboard/resume', {
        ...(filters && parseFilters(filters)),
      })
    },
  }
}
