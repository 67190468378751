import { filterGeos } from '@utils/GeoUtils'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsBenavides'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'

export default {
  title: 'General Dashboard',
  description: 'Dashboard General',
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'Satisfacción General por localización',
      key: 'venta-satisfaccion-zona',
      indicator: 'generalpregunta_1',
      position: [0, 0],
      size: [4, 4],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'Satisfacción General',
      indicator: 'GENERAL_SATISFACCION',
      position: [0, 4],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'NPS',
      key: 'nps-gauge',
      position: [1, 4],
      size: [1, 2],
      indicator: 'generalpregunta_3',
      historic: {
        type: 'line',
        formula: 'nps-10'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Mejores etiquetas',
      position: [2, 4],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Sentimiento negativo con más frecuencia',
      position: [2, 5],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Frecuencia de compra',
      position: [0, 6],
      size: [2, 2],
      indicator: 'general_pregunta_24',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Necesidad de visita a la farmacia',
      position: [2, 5],
      size: [2, 2],
      indicator: 'general_pregunta_25',
      criticalMomentId: 1
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'Ambiente interior',
      position: [0, 8],
      size: [4, 2],
      indicator: 'ATRIB_XP_FARM_AMB_INT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'Ambiente exterior',
      position: [0, 10],
      size: [2, 2],
      indicator: 'ATRIB_XP_FARM_AMB_EXT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'Experiencia de visita',
      position: [2, 10],
      size: [2, 2],
      extraConfigs: {
        chartRadius: '100%', 
        labelFontSize: 40
      },
      indicator: 'ATRIB_XP_FARM_VISITA',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'Satisfacción con variedad',
      position: [0, 12],
      size: [2, 2],
      indicator: 'ATRIB_XP_FARM_SATIF_VAR',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'Mejores farmacias evaluadas',
      position: [2, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'generalpregunta_1',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'Farmacias a mejorar',
      position: [3, 12],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'generalpregunta_1',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      position: [0, 14],
      size: [4, 4],
      indicator: 'SENTIMENT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 18],
      size: [4, 2],
      indicator: 'WORD_COUNT',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByMonthRegression',
      title: '# de Encuestas por mes',
      position: [0, 20],
      size: [4, 2],
      indicator: 'SURVEY_COUNT',
      extras: {
        periods: '6'
      },
      mapper: (data: any, extra: any): any => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          id: idx,
          group: item.group,
          label: item.groupName,
          value:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount']
              : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
        }))
      },
    })
  ]
}
