import React, { memo } from 'react'
import { Layout } from 'antd'
import EpsilonHeader from './EpsilonHeader'
import { useStore } from 'react-redux'
import persistStore from 'redux-persist/es/persistStore'
import useActions from '@hooks/useActions'
import modules from '@modules'
import useConnection from '@hooks/useConnection'

import './EpsilonLayout.less'
import { useLocation } from 'react-router-dom'

const { Content, Footer } = Layout

type PropsType = {
  children?: Array<React.ReactNode>
}

const EpsilonLayout = memo(({ children }: PropsType): JSX.Element => {
  const store = useStore()
  const dispatchers = useActions(modules.actions.auth, [])
  const { pathname } = useLocation()

  const { data, menuItems, title } = useConnection<{
    data: UserAccount
    menuItems: Array<MenuType>
    title: string
  }>(['account.data', 'appData.menuItems', 'appData.title'])

  const onLogout = () => {
    // INICIO - Redux clear store. si no funciona comentar.
    const persistor = persistStore(store)
    const resetStore = async () => {
      await persistor.purge()
      //store.dispatch(resetStore())
      await persistor.flush()
    }
    resetStore()

    // FIN - Redux clear store. si no funciona comentar.
    dispatchers.signout()
  }

  return (
    <Layout className='epsilon-layout'>
      <EpsilonHeader
        type='animated'
        title={title}
        //fixedHeader={fixedHeader}
        onLogout={onLogout}
        profileData={data}
      />
      {/*pathname === '/dashboard' && <div className='epsilon-hero-bg' />*/}
      <Content className='epsilon-content'>{children || null}</Content>

      <Footer className='epsilon-footer'>
        Epsilon ©2024 a product of Clientship
      </Footer>
    </Layout>
  )
})

export default EpsilonLayout
