import React from 'react'
import { Layout, Popover, Typography } from 'antd'
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons'

import ProfileMenu from '@components/ProfileMenu'
import AlertsNotification from '@components/AlertsNotification'
import useConnection from '@hooks/useConnection'
import useActions from '@hooks/useActions'
import modules from '@modules'
import MenuDropdown from '@components/EpsilonMenuDropdown/EpsilonMenuDropdown'
import useFilters from '@hooks/useFilters'

import EpsilonFilterSidebar from '@components/EpsilonFilterSidebar/EpsilonFilterSidebar'
import { EpsilonFilterBar } from '@components/FilterBar/EpsilonFilterBar'
import { EpsilonSelectedFilterBar } from '@components/FilterBar'
import SurveyCounter from '@components/SurveyCounter'
import useClientConfig from 'config/clients/useClientConfig'

import './EpsilonHeader.less'
import LanguageSwitcher from '@components/LanguageSwitcher/LanguageSwitcher'
import useClientTranslation from 'config/clients/useClientTranslation'
import { SurveysCountInfo } from '@modules/AppDataModule'
import NotificationStatus from '@components/NotificationStatus'
import { canAccessAlertsStatus } from '@utils/profileUtils'

type PropsType = {
  type: string
  title?: string
  //fixedHeader: boolean
  onLogout: () => void
  profileData?: UserAccount
}

const EpsilonHeader = (props: PropsType): JSX.Element => {
  const { translate } = useClientTranslation()

  const { getNotificationsConfig } = useClientConfig()

  const { onLogout, profileData } = props
  const { filters, filtersData } = useFilters()

  const { surveysCount } = useConnection<{
    surveysCount: SurveysCountInfo | null
  }>(['appData.surveysCount'])

  const dispatchers = useActions(modules.actions.appData, [])

  React.useEffect(() => {
    dispatchers.getSurveyCount({
      filters: filters,
    })
  }, [filters])

  const {
    getClientshipLogo,
    getClientLogoSmall,
    supportsInternationalization,
  } = useClientConfig()

  const showFilterBar = profileData?.authorities.indexOf('ROLE_ADMIN') === -1
    && !window.location.href.includes('power-intelligence')
    && !window.location.href.includes('omoikane-ai')

  //console.log('Rendering epsilon header')
  //console.log(profileData)

  return (
    <Layout.Header className='lmt-epsilon-header'>
      <div className='epsilon-main-header'>
        <div className='epsilon-header-logos'>
          <div className='limetropy-logo'>
            <img src={getClientshipLogo()} alt='' />
          </div>
          <div className='client-logo'>
            <img src={getClientLogoSmall()} alt='' />
          </div>
        </div>

        <div className='epsilon-header-actions'>
          {supportsInternationalization() && (
            <div className='epsilon-header-language'>
              <LanguageSwitcher />
            </div>
          )}
          { // Alerts Status slide side bar
            profileData?.authorities.indexOf('ROLE_ADMIN') === -1
            && getNotificationsConfig().enabled
            && <div className='epsilon-header-notifications'>
              <NotificationStatus />
            </div>
          }
          { // Alerts Status slide side bar
            profileData?.authorities.indexOf('ROLE_ADMIN') === -1
            && canAccessAlertsStatus(profileData.userProfiles)
            && <div className='epsilon-header-alerts'>
              <AlertsNotification />
            </div>
          }

          {profileData && (
            <Popover
              overlayClassName='profile-dropdown'
              overlayStyle={{ width: 220, maxWidth: 220 }}
              content={
                <ProfileMenu onLogout={onLogout} profileData={profileData} />
              }
              placement='bottomLeft'
              trigger={['click']}
            >
              <div className='epsilon-header-dropdown'>
                <Typography className='text-profile-style'>
                  {translate('general.profile')}
                </Typography>

                <div className='epsilon-header-dropdown-icon-container'>
                  <CaretDownOutlined style={{ color: '#FFFFFF' }} />
                </div>
              </div>
            </Popover>
          )}
        </div>
      </div>
      <div className='epsilon-sub-header'>
        <MenuDropdown />
        {showFilterBar && <EpsilonFilterSidebar filters={filters} filtersData={filtersData} />}
        {showFilterBar && <EpsilonFilterBar filters={filters} filtersData={filtersData} />}
        <div className='flex-1' />
        {showFilterBar && surveysCount && surveysCount !== null
          ? <SurveyCounter />
          : <span className='survey-count'></span>}
      </div>
      {showFilterBar && (
        <div className='epsilon-selected-filters-header'>
          <EpsilonSelectedFilterBar
            filters={filters}
            filtersData={filtersData}
          />
        </div>
      )}
    </Layout.Header>
  )
}

export default EpsilonHeader
