import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, yesNoMapperHa } from './commonWidgetsHa'
import { quarterColors } from './default'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Instalaciones',
    type: 'label',
    position: [1, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Experiencia General',
    type: 'label',
    position: [2, 0],
    size: [2, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_6-by-self',
    indicator: 'durante_a_pregunta_6',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Servicios e instalaciones funcionales',
    position: [1, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_8-by-self',
    indicator: 'durante_a_pregunta_8',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general',
    position: [2, 1],
    size: [2, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_41-by-self',
    indicator: 'durante_a_pregunta_41',
    criticalMomentInclude: [9951]
  }),
  {
    title: 'Servicios alimenticios',
    type: 'label',
    position: [0, 3],
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención telefónica',
    position: [0, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_11-by-self',
    indicator: 'durante_a_pregunta_11',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Agrado de los alimentos',
    position: [1, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_13-by-self',
    indicator: 'durante_a_pregunta_13',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención del personal durante el abastecimiento',
    position: [2, 4],
    size: [2, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_15-by-self',
    indicator: 'durante_a_pregunta_15',
    criticalMomentInclude: [9951]
  }),
  {
    title: 'Atención personal de enfermería',
    type: 'label',
    position: [0, 6],
    size: [4, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención oportuna, botón de llamado',
    position: [0, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_22-by-self',
    indicator: 'durante_a_pregunta_22',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción del trato recibido del personal de enfermería',
    position: [1, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_24-by-self',
    indicator: 'durante_a_pregunta_24',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿El personal de enfermería se presentó?',
    position: [2, 7],
    size: [1, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'durante_a_pregunta_18',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿El personal de enfermería utilizó un lenguaje fácil de entender?',
    position: [3, 7],
    size: [1, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'durante_a_pregunta_20',
    criticalMomentInclude: [9951]
  }),
  {
    title: 'Atención personal médico',
    type: 'label',
    position: [0, 9],
    size: [4, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿El personal médico se presentó?',
    position: [0, 10],
    size: [1, 2],
    mapper: yesNoMapperHa,
    indicator: 'durante_a_pregunta_27',
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿El personal médico  utilizó un lenguaje fácil de entender?',
    position: [1, 10],
    size: [1, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'durante_a_pregunta_29',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención oportuna',
    position: [2, 10],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_31-by-self',
    indicator: 'durante_a_pregunta_31',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción del trato recibido del personal médico',
    position: [3, 10],
    size: [1, 2],
    mapper: haTrendDataMapper,
    key: 'durante_a_pregunta_33-by-self',
    indicator: 'durante_a_pregunta_33',
    criticalMomentInclude: [9951]
  }),
  {
    title: 'Atención General',
    type: 'label',
    position: [0, 12],
    size: [4, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Normas funcionamiento del hospital',
    position: [0, 13],
    size: [1, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'durante_a_pregunta_36',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Trato con respecto',
    position: [1, 13],
    size: [1, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'durante_a_pregunta_37',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Afectación por cambios de turno',
    position: [2, 13],
    size: [1, 2],
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    mapper: yesNoMapperHa,
    indicator: 'durante_a_pregunta_38',
    criticalMomentInclude: [9951]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Medios y personal suficiente',
    position: [3, 13],
    size: [1, 2],
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    mapper: yesNoMapperHa,
    indicator: 'durante_a_pregunta_39',
    criticalMomentInclude: [9951]
  }),
  {
    title: 'Matriz de Priorización',
    type: 'label',
    position: [0, 15],
    size: [4, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'quarterTrend',
    position: [0, 16],
    size: [4, 3],
    extraConfigs: {
      colors: quarterColors
    },
    criticalMomentInclude: [9951]
  }),
]

export default {
  title: 'Durante Dashboard',
  description: 'Dashboard de perfil Durante',
  widgets: (filters: FiltersUI) => {
    /*const isMultiGeo = filters.geographicDistributions?.length > 1
      || filters.geographicDistributions?.length < 4

    const isSingleGeo = filters.geographicDistributions?.length <= 1
      || filters.geographicDistributions?.length >= 4

    if (isMultiGeo) {
      return allWidgets.filter((wdg: any) => wdg.widgetName !== 'haTrendByGeo')
    } else if (isSingleGeo) {
      return allWidgets.filter((wdg: any) => wdg.widgetName !== 'haTrendByGeo')
    }*/

    return allWidgets
  },
} as DasboardConfig
