import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'
import { NOTIFICATION_SURVEY_MODULE_NAME } from './NotificationSurveysModule'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/notification-surveys',
  layout: true,
  getComponent: async () => {
    const NotificationSurveysLayout = await import(
      /* webpackChunkName: "surveys-modules" */ './components/NotificationSurveysLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "surveys-modules" */ './NotificationSurveysModule'
    )

    injectReducer(store, { key: NOTIFICATION_SURVEY_MODULE_NAME, reducer, })
    injectSagas(store, rootSagas)

    return {
      children: NotificationSurveysLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/:notificationId/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const NotificationSurveysTableView = await import('../NotificationSurveys/components/NotificationSurveysTableView')
        return { children: NotificationSurveysTableView.default }
      },
    },
    {
      path: '/by-account/:customerCode/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const NotificationSurveysTableView = await import('../NotificationSurveys/components/NotificationSurveysTableView')
        return { children: NotificationSurveysTableView.default }
      },
    },

    /*{
      path: '/detail/:id/',
      title: 'surveys.detail_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const ClientAccountDetailView = await import('./components/ClientAccountDetailView')
        return { children: ClientAccountDetailView.default, }
      },
    },*/
  ],
})

