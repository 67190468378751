import { injectReducer } from '@modules/reducers'
import { injectSagas } from '@modules/sagas'
import { ACCOUNT_MESSAGE_MODULE_NAME } from './AccountMessagesModule'

// Sync route definition
export default (store: Store): RouteConfig => ({
  path: '/account-messages',
  layout: true,
  getComponent: async () => {
    const ClientAccountMessagesAdminLayout = await import(
      /* webpackChunkName: "surveys-modules" */ './components/AccountMessagesLayout'
    )
    const { reducer, rootSagas, } = await import(
      /* webpackChunkName: "surveys-modules" */ './AccountMessagesModule'
    )

    injectReducer(store, { key: ACCOUNT_MESSAGE_MODULE_NAME, reducer, })
    injectSagas(store, rootSagas)

    return {
      children: ClientAccountMessagesAdminLayout.default,
    }
  },
  childRoutes: [
    {
      path: '/:id/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const AccountMessagesTableView = await import('../AccountMessages/components/AccountMessagesTableView')
        return { children: AccountMessagesTableView.default }
      },
    },
    {
      path: '/',
      title: 'surveys.list_title', // Here you can use a plain string o a transalation key
      getComponent: async () => {
        const AccountMessagesTableView = await import('../AccountMessages/components/AccountMessagesTableView')
        return { children: AccountMessagesTableView.default }
      },
    },
  ],
})

