import { renderForCriticalMoment } from '../dashHelper'
import benamedic from './benamedic'
import canalDigital from './canalDigital'
import canalTelefonico from './canalTelefonico'
import consultorio from './consultorio'
import fotos from './fotos'
import encuestaGeneral from './encuestaGeneral'
import resume from './resume'

export default {
  title: 'Demo Dashboard',
  description: 'Dashboard de perfil Demo',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    ...resume.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [0, 29]),
      })),
    ...encuestaGeneral.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [9953]),
      })),

    ...benamedic.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12355]),
      })),

    ...canalDigital.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [9951]),
      })),

    ...canalTelefonico.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [9952]),
      })),

    ...consultorio.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12552]),
      })),

    ...fotos.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12952]),
      })),

  ],
} as DasboardConfig
