import { answeredMapper, calidadDatosMapper, invalidMapper, porcRespuestasMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar, radialMapperByIndicator } from "../widgetsLibrary/widgetsLine"
import { commonWidgets, getCmID, jacCustomerJourneyMapper } from "./commonWidgetsJac"

export default {
  title: "Demo Venta Dashboard",
  description: "Demo de Nacional Venta",
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets: (filters: FiltersUI) => [
    getLineDetailedRadar({
      mainIndicator: 'CSATVN',
      radialIndicators: [
        'recepcionvn',
        'atencionvn',
        'explicacionvn',
        'instalaciones',
        'entregavn'
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 3],
      title: 'widgets.snByGeo',
      criticalMomentInclude: [getCmID('venta')],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        maxValue: 100,
      },
      groupByLevel: 1,
      useNormalizedRadial: false,
      indicatorsNames: {
        'recepcionvn': 'Recepción',
        'atencionvn' : 'Atención y amabilidad',
        'explicacionvn': 'Conocimiento',
        'instalaciones': 'Instalaciones',
        'entregavn': 'Entrega',
      },
    }),
    //1
    {
      title: 'labels.surveys',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'labels.alerts',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      subTitle: 'widgets.surveyCountMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      subTitle: 'widgets.surveyCountWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentInclude: [getCmID('venta')],
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentInclude: [getCmID('venta')],
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [0, 6],
      size: [2, 2],
      indicator: 'CSATVN',
      criticalMomentInclude: [getCmID('venta')],
      extras: {
        periods: 6,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 6],
      size: [1, 2],
      indicator: 'calidad-gauge',
      mapper: calidadDatosMapper,
      criticalMomentInclude: [getCmID('venta')],
      url: 'dashboard/status',
      extraIndicatorConfigs: {
        keyExtract: ['resume'],
        singleton: true
      },
      extras: {
        useLcmApi: true,
        workflowName: 'venta'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'venta-respuestas-gauge',
      mapper: porcRespuestasMapper,
      position: [3, 6],
      size: [1, 2],
      criticalMomentInclude: [getCmID('venta')],
      url: 'dashboard/status',
      extraIndicatorConfigs: {
        keyExtract: ['resume'],
        singleton: true
      },
      extras: {
        useLcmApi: true,
        workflowName: 'venta'
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: jacCustomerJourneyMapper,
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      //indicator: 'MERGE',
      position: [0, 8],
      size: [4, 2],
      extraConfigs: {
        chartRadius: '70%',
        groupNameFormat: 'description',
        maxValue: 100,
      },
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 10],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstRotativasVenta',
      position: [2, 10],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de estaciones',
      position: [0, 11],
      size: [2, 2],
      indicator: 'CSATVN',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 11],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'CSATVN',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 11],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'CSATVN',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'widgets.wordCount',
      position: [0, 13],
      size: [4, 2],
      criticalMomentInclude: [getCmID('venta')],
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: "widgets.sentimentAnalysis",
      position: [0, 15],
      size: [4, 3],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'widgets.ishikawa',
      position: [0, 18],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('venta')],
    })
  ],
} as DasboardConfig
